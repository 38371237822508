<template>
  <LoginCard>
    <template slot="header">Tilbakestill passord</template>
    <template slot="text">
      dersom det er en konto med samme epost får du en mail med instrukser om hvordan du går videre.
    </template>
    <template slot="body">
      <label for="user">Epost</label>
      <form @submit="resetPasswordHandler" class="signin-form" novalidate="true">
        <input
          class="input"
          :class="{ 'error-input': error }"
          type="text"
          id="user"
          name="user"
          v-model.trim="email"
        />
        <span v-if="error" class="error-text">{{ error }}</span>
        <MainButton @click="resetPasswordHandler">Tilbakestill</MainButton>
      </form>

      <router-link :to="{ name: 'login' }" class="link">Tilbake til logg inn</router-link>
    </template>
  </LoginCard>
</template>

<script>
import LoginCard from '@/components/elements/LoginCard.vue';
import MainButton from '@/components/elements/MainButton.vue';

export default {
  components: {
    LoginCard,
    MainButton,
  },
  data() {
    return {
      email: '',
      error: null,
    };
  },
  methods: {
    async resetPasswordHandler(e) {
      e.preventDefault();
      this.error = null;
      if (!this.validateEmail(this.email)) {
        this.error = 'Fyll inn en gyldig epostadresse';
      } else {
        const result = await this.$store.dispatch('user/resetPassword', this.email);

        this.$notify({
          group: 'user',
          type: 'info',
          title: 'Vi har (kanskje) sendt deg en mail',
          text: 'dersom vi finner en konto med den oppgitte epostadressen',
        });
      }
    },

    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 200px;
  margin-bottom: $spacing-150;
}

.link {
  color: $color-gray-400;
  margin-top: $spacing-100;
  text-align: center;
  font-size: $font-size--2;
}

.signin-form {
  display: flex;
  flex-direction: column;
}

.error-input {
  border-color: $color-red-300;
}

.error-text {
  font-size: $font-size--2;
  margin-top: -$spacing-100;
  margin-bottom: $spacing-050;
  color: $color-red-600;
}
</style>
